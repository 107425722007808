import { render, staticRenderFns } from "./public.vue?vue&type=template&id=501a38d9&scoped=true"
import script from "./public.vue?vue&type=script&lang=js"
export * from "./public.vue?vue&type=script&lang=js"
import style0 from "./public.vue?vue&type=style&index=0&id=501a38d9&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501a38d9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicHeader: require('/codebuild/output/src3414656624/src/holaluz-conversion-conversionsolar-solarsalesagentui/src/ui/components/PublicHeader/index.js').default,PublicFooter: require('/codebuild/output/src3414656624/src/holaluz-conversion-conversionsolar-solarsalesagentui/src/ui/components/PublicFooter/index.js').default})
